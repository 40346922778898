/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://eclciuvurndjdg7rpnshab7bie.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-srim75whfffe5fmqh3v63siaky",
    "aws_cognito_identity_pool_id": "us-east-1:845a96b0-4e1e-451e-8778-cf568eeb837c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_nFGQhrLxI",
    "aws_user_pools_web_client_id": "6n9mhp6915j89al1jaj06tl29h",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "crownbiosaturnportal-20230822111433-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2b1ksjcddf0u.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "approveddata-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "study_quality_control-prod",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "crownlink-document-storage-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
